
import { mapGetters } from 'vuex'
import dataTable from '@/mixins/dataTable'
export default {
    mixins: [dataTable],
    data() {
        return {
            api: 'themes/game/getBetHistory',
            btnLoading: false,
            sumStatusCondition: 2,
            sortBy: 'deduct_time|desc',
        }
    },
    computed: {
        ...mapGetters({
            gameTypes: 'themes/game/gameTypes',
            betHistory: 'themes/game/betHistory',
        }),

        headers() {
            return [
                { text: this.tl('time'), value: 'deduct_time' },
                {
                    text: this.tl('gameType'),
                    value: 'game_type_name',
                },
                {
                    text: this.tl('gameProvider'),
                    value: 'game_provider_name',
                },
                { text: this.tl('gameName'), value: 'game_name' },
                {
                    text: this.tl('roundId'),
                    value: 'bet_id',
                    sortable: false,
                },
                { text: this.tl('betAmount'), value: 'amount' },
                { text: this.tl('validBet'), value: 'turnover' },
                { text: this.tl('betStatus'), value: 'status' },
                {
                    text: this.tl('amount') + ' ' + this.tl('winLoss'),
                    value: 'win_loss',
                },
            ]
        },
        // Summary bet amount of current page
        betPageAmountSummary() {
            let sum = 0
            for (const bet of this.betHistory?.data) {
                if (bet.status !== this.sumStatusCondition) continue
                if (!isNaN(bet.amount)) {
                    sum += Number.parseFloat(bet.amount)
                }
            }
            return sum
        },
        // Summary turnover of current page
        betPageTurnoverSummary() {
            let sum = 0
            for (const bet of this.betHistory?.data) {
                if (bet.status !== this.sumStatusCondition) continue
                if (!isNaN(bet.turnover)) {
                    sum += Number.parseFloat(bet.turnover)
                }
            }
            return sum
        },
        // Summary win loss of current page
        betPageWinLossSummary() {
            let sum = 0
            for (const bet of this.betHistory?.data) {
                if (bet.status !== this.sumStatusCondition) continue
                if (!isNaN(bet.win_loss)) {
                    sum += Number.parseFloat(bet.win_loss)
                }
            }
            return sum
        },
    },
    mounted() {
        this.customFilters = {
            language: this.handleCookie('wl_locale'),
            start_date: this.$dayjs().format('YYYY-MM-DD') + ' 00:00:00',
            end_date: this.$dayjs().format('YYYY-MM-DD') + ' 23:59:59',
        }
    },
    beforeDestroy() {
        this.doCancelAxiosRequest()
    },
    methods: {
        async submitSearch() {
            this.btnLoading = true
            await this.API()
            this.btnLoading = false
        },
        updateStartDate(date) {
            this.customFilters.start_date = date
        },
        updateEndDate(date) {
            this.customFilters.end_date = date
        },
    },
}
