
import { mapState, mapGetters } from 'vuex'
import finance from '~/mixins/common/pages/finance'
export default {
    mixins: [finance],
    data() {
        return {
            paymentType: 'bank',
            steppers: 1,
            formValid: false,
            formCryptoValid: false,
            formData: {
                amount: 0,
            },
            cryptoForm: {
                currency: null,
                amount: null,
                player_crypto_wallet_id: null,
            },
            resultData: {},
            existLoading: false,
            errorDialog: false,
            forceToAddBankAccountModal: false,
            forceToAddWalletAddressModal: false,
            disabledToWithdrawal: false,
            amount: '',
            formattedAmount: {
                amount: '',
                cryptoAmount: '',
            },
            disableAutoDeposit: false,
            disableCryptoPayment: false,
            isWithdrawalSuccessful: false,
        }
    },
    computed: {
        ...mapState({
            loading: (state) => state.themes.withdraw.loading,
            player: (state) => state.player.player,
            cryptoCurrencyList: (state) =>
                state.settings.cryptoWalletAddressList,
            playerCryptoWallets: (state) =>
                state.themes['player-bank'].playerCryptoWallets,
            depositFlow: (state) => state.themes.deposit.depositFlow,
            operationSetting: (state) => state.settings.operationSetting,
            createWithdrawalLoading: (state) =>
                state.themes.withdraw.createWithdrawalLoading,
            createCryptoWithdrawalLoading: (state) =>
                state.themes.withdraw.createCryptoWithdrawalLoading,
        }),
        ...mapGetters({
            exchangeRates: 'themes/settings/exchangeRate',
            bankAccount: 'themes/player-bank/bankAccount',
            swapAllExchangeRate: 'themes/settings/swapAllExchangeRate',
        }),
        depositType() {
            if (!this.depositFlow.deposit_type) return null
            return ~~this.depositFlow.deposit_type
        },
        showCryptoCurrency() {
            if (this.depositType === 1) return false
            return this.depositFlow.is_crypto_payment_enabled
        },
        primaryBankAccount() {
            const foundBankAccount =
                this.bankAccount?.player_bank_accounts?.find(
                    (account) => account.is_primary
                )
            return foundBankAccount || {}
        },
    },
    watch: {
        player(value) {
            if (value.currency) {
                this.checkIfWithdrawalExist()
            }
        },
    },
    async mounted() {
        await this.checkIfWithdrawalExist()
        this.$root.withdrawExistChange = (item) => {
            if (!item?.is_exist) {
                if (this.steppers !== 3) {
                    this.steppers = 1
                }
                this.resultData = {}
                this.disableAutoDeposit = false
                this.disableCryptoPayment = false
            } else {
                this.resultData = item
            }
        }

        this.$store.dispatch(
            'settings/operationSetting',
            this.$cookie.get('player')?.currency
        )
        if (!this.playerCryptoWallets.length) {
            this.$store.dispatch('themes/player-bank/playerCryptoWallet')
        }

        // subscribe socket actions for deposit
        this.subscribeAction = this.$store.subscribeAction(
            (mutation, state) => {
                if (mutation.type === 'sockets/successfulWithdrawal') {
                    this.steppers = 3
                    this.isWithdrawalSuccessful = true
                }
                if (mutation.type === 'sockets/revokeWithdrawal') {
                    this.steppers = 3
                    this.isWithdrawalSuccessful = false
                }
            }
        )
    },
    beforeDestroy() {
        this.doCancelAxiosRequest()
        // unsubscribe the store action
        if (this.subscribeAction) {
            this.subscribeAction()
        }
    },
    destroyed() {
        this.$root.withdrawExistChange = null
    },
    methods: {
        async checkIfWithdrawalExist() {
            if (this.player?.has_bank_account) {
                this.existLoading = true
                const result = await this.$store.dispatch(
                    'themes/withdraw/checkExistWithdrawal'
                )
                if (result?.is_exist) {
                    this.resultData = result
                    this.steppers = 2
                    if (result.channel === 'crypto') {
                        this.disableAutoDeposit = true
                        this.paymentType = 'crypto'
                    } else if (
                        result.channel === 'third_party' ||
                        result.channel === 'manual_transfer'
                    ) {
                        this.disableCryptoPayment = true
                        this.paymentType = 'bank'
                    }
                }
                this.existLoading = false
            }
        },
        doWithdraw(result) {
            this.disableCryptoPayment = true
            this.resultData = result
            this.steppers = 2
        },
        cancelPending(result) {
            this.disableAutoDeposit = false
            this.disableCryptoPayment = false
            this.$toast.success(this.tl(result.message))
            this.resultData = {}
            this.steppers = 1
        },
        onCreateCryptoWithdrawal(response) {
            this.disableAutoDeposit = true
            this.resultData = response
            this.steppers++
        },
        onChangePaymentType(type) {
            this.steppers = 1
            this.paymentType = type
        },
    },
}
