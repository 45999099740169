import { render, staticRenderFns } from "./MyProfile.vue?vue&type=template&id=007b4ef8&scoped=true"
import script from "./MyProfile.vue?vue&type=script&lang=js"
export * from "./MyProfile.vue?vue&type=script&lang=js"
import style0 from "./MyProfile.vue?vue&type=style&index=0&id=007b4ef8&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "007b4ef8",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CommonsPhoneInput: require('/app/components/Commons/PhoneInput/index.vue').default})
