
import resetPassword from '~/mixins/common/pages/reset-password'
export default {
    mixins: [resetPassword],
    methods: {
        resetForm() {
            this.$refs?.refPassword?.reset()
        },
    },
}
