import { render, staticRenderFns } from "./ReferFriend.vue?vue&type=template&id=71c95f9a"
import script from "./ReferFriend.vue?vue&type=script&lang=js"
export * from "./ReferFriend.vue?vue&type=script&lang=js"
import style0 from "./ReferFriend.vue?vue&type=style&index=0&id=71c95f9a&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CommonsCustomQrCode: require('/app/components/Commons/CustomQrCode.vue').default,CommonsDatePicker: require('/app/components/Commons/DatePicker.vue').default,Theme3ComponentsFriendList: require('/app/components/Theme3/Components/FriendList.vue').default,Theme3ComponentsClaimHistory: require('/app/components/Theme3/Components/ClaimHistory.vue').default,CommonsInviteAndEarnBannerDesktop: require('/app/components/Commons/InviteAndEarnBannerDesktop.vue').default})
