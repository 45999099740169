
export default {
    props: {
        item: {
            type: [Array, Object],
            default: () => {},
        },
        accountHolderName: {
            type: String,
            default: null,
        },
        index: {
            type: Number,
            default: 0,
        },
    },
    data() {
        return {
            setBankPrimaryLoading: false,
            deleteBankLoading: false,
        }
    },
    methods: {
        getLimitedName(text, limit = 30) {
            if (!text) return null
            const displayLimit = limit
            if (text.length > displayLimit) {
                return text.substring(0, displayLimit) + '...'
            } else {
                return text
            }
        },
        async onDeleteBank(id) {
            if (!window.confirm(`${this.tl('areYouSureToDelete')}?`))
                return null
            this.deleteBankLoading = true
            const response = await this.$store.dispatch(
                'themes/player-bank/doDeletePlayerBankAccount',
                id
            )
            if (response) {
                this.$toast.success(this.tl('deleteSuccessful'))
            }
            this.deleteBankLoading = false
        },
        async onSetPrimary(id) {
            this.setBankPrimaryLoading = true
            const form = { is_primary: true }
            const response = await this.$store.dispatch(
                'themes/player-bank/doUpdatePlayerBankAccount',
                { form, accountId: id }
            )
            if (response) {
                this.$toast.success(this.tl('setPrimaryBankAccountSuccessful'))
            }
            this.setBankPrimaryLoading = false
        },
    },
}
