
export default {
    name: 'BankAddEmptyCard',
    props: {
        myClass: {
            type: String,
            default: '',
        },
        myStyle: {
            type: Object,
            default: () => {},
        },
        width: {
            type: String,
            default: '300',
        },
        loading: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        onClickCard() {
            if (this.loading) return
            this.$emit('click')
        },
    },
}
