import { render, staticRenderFns } from "./MyDeposit.vue?vue&type=template&id=16e3a6fd&scoped=true"
import script from "./MyDeposit.vue?vue&type=script&lang=js"
export * from "./MyDeposit.vue?vue&type=script&lang=js"
import style0 from "./MyDeposit.vue?vue&type=style&index=0&id=16e3a6fd&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "16e3a6fd",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CommonsNuxtImage: require('/app/components/Commons/NuxtImage.vue').default,Theme3DepositBanner: require('/app/components/Theme3/DepositBanner.vue').default,Theme3ProfileLayout: require('/app/components/Theme3/ProfileLayout.vue').default,Theme3ForceToAddBankAndWallet: require('/app/components/Theme3/ForceToAddBankAndWallet.vue').default})
