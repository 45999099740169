
import { mapState } from 'vuex'
export default {
    data() {
        return {
            page: 1,
            tab: null,
            claimableLoading: false,
            rollingRewardLoading: false,
            activeBonusLoading: false,
            endBonusLoading: false,
            code: null,
            claimNowButtonLoading: false,
            loadingClaimPromotionCode: false,
        }
    },
    computed: {
        ...mapState({
            promotions: (state) => state.themes.promotion.promotions,
            activePromotion: (state) => state.themes.promotion.activePromotion,
            endPromotion: (state) => state.themes.promotion.endPromotion,
            rollingReward: (state) => state.themes.promotion.rollingReward,
            histories: (state) => state.themes.promotion.histories,
            player: (state) => state.player.player,
        }),
        pageTotalAmount() {
            const sum = this.histories?.data?.reduce((accumulator, object) => {
                return accumulator + parseFloat(object.bonus_amount)
            }, 0)
            return sum
        },
    },
    watch: {
        async tab(value) {
            // 0 = claimable
            if (~~value === 0) {
                if (this.promotions.length) return
                this.onFetchClaimablePromotions()
                this.onFetchRollingReward()
            }
            // 1 = active promotion
            if (~~value === 1) {
                if (this.activePromotion.display_name) return
                this.onFetchActivePromotions()
            }
            // 2 = ended promotion
            if (~~value === 2) {
                if (this.endPromotion.length) return
                this.onFetchEndedPromotions()
            }
            // 3 = ended promotion
            if (~~value === 3) {
                if (this.histories?.data?.length) return
                await this.onFetchHistories()
                this.$forceUpdate()
            }
        },
        page(value) {
            this.onFetchHistories(value)
        },
    },
    beforeDestroy() {
        this.doCancelAxiosRequest()
    },
    methods: {
        async onFetchClaimablePromotions() {
            this.claimableLoading = true
            await this.$store.dispatch('themes/promotion/getPromotions')
            this.claimableLoading = false
        },
        async onFetchRollingReward() {
            this.rollingRewardLoading = true
            await this.$store.dispatch('themes/promotion/rollingReward')
            this.rollingRewardLoading = false
        },
        async onFetchActivePromotions() {
            this.activeBonusLoading = true
            await this.$store.dispatch('themes/promotion/activePromotion')
            this.activeBonusLoading = false
        },
        async onFetchEndedPromotions() {
            this.endBonusLoading = true
            await this.$store.dispatch('themes/promotion/endPromotion')
            this.endBonusLoading = false
        },
        async onFetchHistories(page = 1) {
            this.claimableLoading = true
            await this.$store.dispatch('themes/promotion/histories', {
                limit: 10,
                page,
            })
            this.claimableLoading = false
        },
        getBonusType(item) {
            if (~~item.bonus_type === 1) return this.tl('promotion')
            if (~~item.bonus_type === 2) return this.tl('promoCode')
            if (~~item.bonus_type === 3) return this.tl('rollingReward')
            if (~~item.bonus_type === 4) return this.tl('inviteAndEarn')
            if (~~item.bonus_type === 5) return '' // this.tl('depositWithoutPromotion')
            return '-'
        },
        async onClaimPromotionCode() {
            this.loadingClaimPromotionCode = true
            if (!this.code) return (this.loadingClaimPromotionCode = false)
            if (this.code) {
                const res = await this.$store.dispatch(
                    'themes/promotion/claimPromotionCode',
                    this.code?.trim()
                )
                if (res) {
                    this.code = null
                    this.$toast.success(this.tl('claimPromotionCodeSucceeded'))
                }
                this.loadingClaimPromotionCode = false
            }
        },
        async claimNowRolling() {
            this.claimNowButtonLoading = true
            const response = await this.$store.dispatch(
                'promotion/claimNowRolling'
            )
            if (response) {
                this.$toast.success(this.tl('claimSuccessful'))
                await this.onFetchRollingReward()
            }
            this.claimNowButtonLoading = false
        },
        claimPromotionFinished(result) {
            if (result) {
                this.tab = 1
            }
        },
    },
}
