
import { mapGetters } from 'vuex'
import playerProfile from '~/mixins/common/pages/player-profile'
export default {
    mixins: [playerProfile],
    data() {
        return {
            tab: null,
            tabModel: 1,
            claimLoading: false,
            customFilters: {},
            loading: false,
        }
    },
    computed: {
        ...mapGetters({
            referFriend: 'themes/refer-friend/referFriend',
        }),
    },
    mounted() {
        this.customFilters = {
            language: this.handleCookie('wl_locale'),
            sort: 'claim_time|desc',
            start_date: this.$dayjs().format('YYYY-MM-DD'), // BZ Team needs both start and end dates to be today date
            end_date: this.$dayjs().format('YYYY-MM-DD'),
        }
    },
    beforeDestroy() {
        this.doCancelAxiosRequest()
    },
    methods: {
        doSubmitSearch() {
            this.API()
        },
        async claimCommission() {
            this.claimLoading = true
            const result = await this.$store.dispatch(
                'themes/refer-friend/doClaimCommission'
            )
            if (result) {
                await this.$store.dispatch('player/profile')
                this.API()
                this.$toast.success(this.tl('claimCommissionSuccess'))
            }
            this.claimLoading = false
        },
        updateStartDate(date) {
            this.customFilters.start_date = date
        },
        updateEndDate(date) {
            this.customFilters.end_date = date
        },
        async dispatch(api, filter) {
            await this.$store.dispatch(api, filter)
        },
        API() {
            const searchList = [
                {
                    api: 'themes/refer-friend/getReferFriend',
                    filter: {
                        ...this.customFilters,
                    },
                },
                {
                    api: 'themes/refer-friend/friendList',
                    filter: {
                        ...this.customFilters,
                    },
                },
            ]

            this.loading = true

            const requests = searchList.map((el) =>
                this.dispatch(el.api, el.filter)
            )

            Promise.all(requests)
                .then((_) => {})
                .catch((_) => {})
                .finally(() => (this.loading = false))
        },
    },
}
