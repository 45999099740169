
import { mapState, mapGetters } from 'vuex'
import playerProfile from '~/mixins/common/pages/player-profile'
export default {
    mixins: [playerProfile],
    data() {
        return {
            panel: 0,
            deleteLoading: {},
            formValid: false,
            formData: {
                currency: '',
                email: '',
                phone: '',
                date_of_birth: null,
                address: '',
                city: '',
                country: '',
                province: '',
                social_info: [
                    {
                        id: null,
                        social_media_type: null,
                        social_media_id: '',
                    },
                ],
            },
            existForm: {},
        }
    },
    computed: {
        ...mapGetters({
            loading: 'themes/player/loading',
        }),
        ...mapState({
            loadingPlayer: (state) => state.player.loading,
            player: (state) => state.player.player,
        }),
        isMatch() {
            return this.isEqual(this.formData, this.existForm)
        },
    },
    watch: {
        player(value) {
            this.setFormDataDefaultVal(value)
        },
    },
    mounted() {
        this.setFormDataDefaultVal(this.player)
    },
    beforeDestroy() {
        this.doCancelAxiosRequest()
    },
    methods: {
        addNewSocialItem() {
            if (this.formData?.social_info?.length > 5) return
            this.formData?.social_info?.push({
                id: null,
                social_media_type: null,
                social_media_id: '',
            })
        },
        async deleteSocialItem(item, index) {
            if (item.id) {
                if (window.confirm(this.tl('areYouSure'))) {
                    this.deleteLoading[item.id] = true
                    const result = await this.$store.dispatch(
                        'themes/player/doDeleteSocial',
                        item.id
                    )
                    if (result) {
                        this.$delete(this.formData?.social_info, index)
                    }
                    this.deleteLoading[item.id] = false
                }
            } else {
                this.$delete(this.formData?.social_info, index)
            }
        },
        setFormDataDefaultVal(player) {
            if (!player) return
            const socialInfo = player?.social_info
            this.formData = {
                currency: player?.currency || '',
                email: player?.email || '',
                phone: player?.phone || '',
                date_of_birth: player?.date_of_birth || null,
                address: player?.address || '',
                city: player?.city || '',
                country: player?.country || '',
                province: player?.province || '',
                social_info: player?.social_info?.length
                    ? JSON.parse(JSON.stringify(socialInfo))
                    : [
                          {
                              id: null,
                              social_media_type: null,
                              social_media_id: '',
                          },
                      ],
            }
            this.existForm = JSON.parse(JSON.stringify(this.formData))
        },
    },
}
