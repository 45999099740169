
import { mapGetters } from 'vuex'
export default {
    name: 'CryptoWalletCard',
    props: {
        item: {
            type: [Array, Object],
            default: () => {},
        },
    },
    data() {
        return {
            deleteBankLoading: false,
            setPrimaryLoading: false,
        }
    },
    computed: {
        ...mapGetters({
            bankAccount: 'themes/player-bank/bankAccount',
        }),
    },
    methods: {
        async onDeleteWallet(id) {
            if (!window.confirm(`${this.tl('areYouSureToDelete')}?`))
                return null
            this.deleteBankLoading = true
            const response = await this.$store.dispatch(
                'themes/player-bank/deletePlayerCryptoWallet',
                id
            )
            if (response) {
                this.$toast.success(this.tl('deleteSuccessful'))
            }
            this.deleteBankLoading = false
        },
        async onSetPrimary(id) {
            this.setPrimaryLoading = true
            const form = { is_primary: true }
            const response = await this.$store.dispatch(
                'themes/player-bank/updatePlayerCryptoWallet',
                { ...form, id }
            )
            if (response) {
                this.$toast.success(this.tl('setPrimaryCryptoWalletSuccessful'))
            }
            this.setPrimaryLoading = false
        },
    },
}
