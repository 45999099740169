
import { mapGetters, mapState } from 'vuex'
import cms from '~/mixins/common/cms'
export default {
    name: 'MyDepositDesktop',
    mixins: [cms],
    data() {
        return {
            disabledToDeposit: false,
            paymentType: null,
            disabledBank: true,
            disabledCrypto: true,
            currencyFilter: null,
            forceToAddBankAccountModal: false,
        }
    },
    computed: {
        ...mapState({
            player: (state) => state.player.player,
            depositFlow: (state) => state.themes.deposit.depositFlow,
            checkExist: (state) => state.themes.deposit.checkExist,
            checkExistLoading: (state) =>
                state.themes.deposit.checkExistLoading,
            checkDeposit: (state) => state.themes.deposit.checkDeposit,
            getCurrencyLoading: (state) => state.finance.getCurrencyLoading,
            createDepositLoading: (state) =>
                state.themes.deposit.createDepositLoading,
            createCryptoDepositLoading: (state) =>
                state.themes.deposit.createCryptoDepositLoading,
            getCompanyBankLoading: (state) =>
                state.themes['company-bank'].loading,
        }),
        ...mapGetters({
            depositInstruction: 'cms/depositInstruction',
            companyBankAccounts: 'themes/company-bank/companyBankAccount',
            getCurrencyByType: 'finance/getCurrencyByType',
            getCompanyBankAccountByCurrency:
                'themes/company-bank/getCompanyBankAccountByCurrency',
        }),
        currencyList() {
            return this.getCurrencyByType(1)
        },
        depositType() {
            if (!this.depositFlow.deposit_type) return null
            return ~~this.depositFlow.deposit_type
        },
        showBankPayment() {
            if (this.depositType === 1) return false
            return this.depositFlow.is_bank_transfer_enabled
        },
        showCryptoCurrency() {
            if (this.depositType === 1) return false
            return this.depositFlow.is_crypto_payment_enabled
        },
        // import dynamic components
        depositType2PaymentComponent() {
            const componentPath = 'Theme3DesktopPaymentMethod'
            if (this.paymentType === 'crypto')
                return `${componentPath}CryptoCurrency`
            return `${componentPath}BankTransfer`
        },
    },
    watch: {
        player(value) {
            this.checkWebsiteSettings()
        },
        depositFlow(value) {
            if (!this.paymentType) {
                if (value?.is_bank_transfer_enabled) {
                    this.paymentType = 'bank'
                } else if (value?.is_crypto_payment_enabled) {
                    this.paymentType = 'crypto'
                }
            }
            if (value?.reg_flow) {
                const hasBankAccount = this.player.has_bank_account
                if (hasBankAccount === false && ~~value?.reg_flow === 3) {
                    this.forceToAddBankAccountModal = true
                } else {
                    this.forceToAddBankAccountModal = false
                }
            }
        },
        checkExist(value) {
            if (value?.is_exist) {
                this.enableDisabledPaymentMethod(value)
            }
        },
        currencyList(value) {
            this.setDefaultSelectedCurrency()
        },
    },
    async mounted() {
        this.checkWebsiteSettings()
        this.setDefaultSelectedCurrency()
        if (
            this.depositFlow?.deposit_type === 2 &&
            !this.depositFlow?.is_bank_transfer_enabled
        ) {
            this.paymentType = 'crypto'
        }
        this.isExit()
        await this.$store.dispatch(
            'settings/operationSetting',
            this.$cookie.get('player')?.currency
        )

        this.subscribeAction = this.$store.subscribeAction(
            (mutation, state) => {
                if (
                    mutation.type === 'sockets/revokeDepositByAdmin' ||
                    mutation.type === 'sockets/confirmDepositByAdmin' ||
                    mutation.type === 'sockets/successfulDeposit'
                ) {
                    this.isExit()
                }
            }
        )
    },
    beforeDestroy() {
        // unsubscribe the store action
        if (this.subscribeAction) {
            this.subscribeAction()
        }
    },
    methods: {
        async isExit() {
            const isExist = await this.$store.dispatch(
                'themes/deposit/checkExist'
            )
            this.enableDisabledPaymentMethod(isExist)
        },
        enableDisabledPaymentMethod(data) {
            if (
                data &&
                data.is_exist === true &&
                data.channel === 'manual_transfer'
            ) {
                this.paymentType = 'bank'
                this.disabledBank = false
                this.disabledCrypto = true
            } else if (
                data &&
                data.is_exist === true &&
                data.channel === 'crypto'
            ) {
                this.paymentType = 'crypto'
                this.disabledBank = true
                this.disabledCrypto = false
            } else {
                this.disabledBank = false
                this.disabledCrypto = false
            }
        },
        bankTransferConfirm(value = true) {
            this.disabledCrypto = value
        },
        cryptoTransferConfirm(value = true) {
            this.disabledBank = value
        },
        onConfirmDepositInChildrenComponent(data) {
            const { type, value } = data
            if (type === 'bank_transfer') {
                this.bankTransferConfirm(value)
                return
            }
            if (type === 'crypto_transfer') {
                this.cryptoTransferConfirm(value)
            }
        },
        async getCompanyBankAccounts(currency = null) {
            if (!currency) return
            await this.$store.dispatch(
                'themes/company-bank/getCompanyBankPlayerAccount',
                {
                    currency,
                }
            )
        },
        setDefaultSelectedCurrency() {
            if (!this.currencyList?.length) {
                this.currencyFilter = null
                return
            }
            if (
                !this.currencyList.some((el) => el.code === this.currencyFilter)
            ) {
                this.currencyFilter = null
            }
            if (this.currencyFilter) return
            this.currencyFilter = this.currencyList[0].code
            this.getCompanyBankAccounts(this.currencyFilter)
        },
        onChangeCurrency(code) {
            this.currencyFilter = code
            this.getCompanyBankAccounts(code)
        },
        checkWebsiteSettings() {
            const hasBankAccount = this.player.has_bank_account
            const registrationType = this.player.registration_type

            if (hasBankAccount === false && registrationType === 3) {
                this.forceToAddBankAccountModal = true
            }
        },
    },
}
