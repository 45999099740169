
import { mapGetters, mapState } from 'vuex'
import DataTable from '@/mixins/dataTable'
export default {
    name: 'Theme3TransactionHistory',
    mixins: [DataTable],
    data() {
        return {
            api: 'themes/transaction/getTransactions',
        }
    },
    computed: {
        ...mapState({
            player: (state) => state.player.player,
        }),
        ...mapGetters({
            paymentTypes: 'themes/payment/paymentTypes',
            transaction: 'themes/transaction/transaction',
        }),
        headers() {
            return [
                { text: this.tl('transactionId'), value: 'id', width: 140 },
                { text: this.tl('dateTime'), value: 'created_at' },
                {
                    text: this.tl('transactionType'),
                    value: 'transaction_type',
                    width: 160,
                },
                { text: this.tl('amount'), value: 'amount' },
                {
                    text: this.tl('bonus'),
                    value: 'bonus_amount',
                    sortable: false,
                },
                {
                    text: this.tl('transferType'),
                    value: 'transfer_type',
                    width: 140,
                },
                { text: this.tl('status'), value: 'status' },
            ]
        },
        pageTotalSummary() {
            let sum = 0
            for (const item of this.transaction?.data) {
                sum += Number.parseFloat(item.amount)
            }
            return sum
        },
        pageBonusSummary() {
            let sum = 0
            for (const item of this.transaction?.data) {
                if (!item.bonus_amount || isNaN(item.bonus_amount)) continue
                sum += Number.parseFloat(item.bonus_amount)
            }
            return sum
        },
    },
    mounted() {
        this.$root.isTransactionHistoryPage = true
        this.customFilters = {
            language: this.handleCookie('wl_locale'),
            start_date: '2022-06-01 00:00:00',
            end_date: this.$dayjs().format('YYYY-MM-DD') + ' 23:59:59',
        }
    },
    beforeDestroy() {
        this.doCancelAxiosRequest()
        this.$store.commit('themes/transaction/setTransaction', {
            total: 0,
            total_amount: 0,
            totalPages: 0,
            data: [],
        })
    },
    destroyed() {
        this.$root.isTransactionHistoryPage = false
    },
    methods: {
        doSubmitSearch() {
            this.API()
        },
        updateStartDate(date) {
            this.customFilters.start_date = date
        },
        updateEndDate(date) {
            this.customFilters.end_date = date
        },
    },
}
