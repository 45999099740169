
import DataTable from '~/mixins/dataTable'
export default {
    name: 'Theme3PlayerMessages',
    mixins: [DataTable],
    middleware: ['checkAuth'],
    data: () => ({
        api: 'themes/player/getMessage',
        page: 1,
    }),
    computed: {
        mloading() {
            return this.$store.state.themes.player.loading
        },
        message() {
            return this.$store.state.player.messages
        },
    },
    methods: {
        changePage() {
            if (this.customFilters.page !== this.page) {
                this.customFilters.page = this.page
                this.API()
            }
        },
    },
}
