import { render, staticRenderFns } from "./MyBonus.vue?vue&type=template&id=6c8d0076&scoped=true"
import script from "./MyBonus.vue?vue&type=script&lang=js"
export * from "./MyBonus.vue?vue&type=script&lang=js"
import style0 from "./MyBonus.vue?vue&type=style&index=0&id=6c8d0076&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6c8d0076",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CommonsSkeletonLoader: require('/app/components/Commons/SkeletonLoader.vue').default,CommonsNuxtImage: require('/app/components/Commons/NuxtImage.vue').default,Theme3ComponentsClaimBonusButton: require('/app/components/Theme3/Components/ClaimBonusButton.vue').default,Theme3ComponentsNoBonusAvailable: require('/app/components/Theme3/Components/NoBonusAvailable.vue').default,Theme3ComponentsActiveAndEndedBonus: require('/app/components/Theme3/Components/ActiveAndEndedBonus.vue').default,Theme3ProfileLayout: require('/app/components/Theme3/ProfileLayout.vue').default})
