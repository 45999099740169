
import register from '~/mixins/pages/register/register'

export default {
    name: 'Theme3DesktopRegisterForm',
    mixins: [register],
    data() {
        return {
            isConditional: false,
            rememberMe: false,
        }
    },
    watch: {
        acceptCondition(value) {
            if (value) {
                this.isConditional = false
            } else {
                this.isConditional = true
            }
        },
    },
    methods: {
        register() {
            if (this.acceptCondition) {
                if (this.$refs.form.validate()) {
                    this.onUserRegister()
                }
            } else {
                this.isConditional = true
                this.$toast.error(this.tl('pleaseCheckCheckCondition'))
            }
        },
    },
}
