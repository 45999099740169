import { render, staticRenderFns } from "./WithdrawalPage.vue?vue&type=template&id=7552c567"
import script from "./WithdrawalPage.vue?vue&type=script&lang=js"
export * from "./WithdrawalPage.vue?vue&type=script&lang=js"
import style0 from "./WithdrawalPage.vue?vue&type=style&index=0&id=7552c567&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Theme3DesktopWithdrawalBankTransfer: require('/app/components/Theme3/Desktop/Withdrawal/BankTransfer.vue').default,Theme3DesktopWithdrawalCryptoTransfer: require('/app/components/Theme3/Desktop/Withdrawal/CryptoTransfer.vue').default,Theme3ProfileLayout: require('/app/components/Theme3/ProfileLayout.vue').default})
