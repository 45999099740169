
export default {
    name: 'Theme3DesktopProfilesPlayerBankCard',
    props: {
        item: {
            type: Object,
            required: true,
            default: () => {},
        },
        selectedBankId: {
            type: String,
            default: '',
        },
        accountHolderName: {
            type: String,
            default: '',
        },
        borderColor: {
            type: String,
            default: '#0085FF',
        },
    },
}
